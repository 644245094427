import en from "./text_resources/en.json";
import de from "./text_resources/de.json";
import fr from "./text_resources/fr.json";
import zh from "./text_resources/zh.json";

export default defineI18nConfig(() => ({
  legacy: false,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    de,
    fr,
    zh
  }
}))
